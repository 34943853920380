import React, { useState } from 'react';

import Slide1 from '../img/slide1.png';
import Slide2 from '../img/slide2.png';
import Slide3 from '../img/slide3.png';
import Slide4 from '../img/slide4.png';
import Slide5 from '../img/slide5.png';

function Slider() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        { id: 1, image: Slide1, title: 'Slide 1' },
        { id: 2, image: Slide2, title: 'Slide 2' },
        { id: 3, image: Slide3, title: 'Slide 3' },
        { id: 4, image: Slide4, title: 'Slide 4' },
        { id: 5, image: Slide5, title: 'Slide 5' }
    ];

    const nextSlide = () => {
        setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
    };

    const prevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
    };

    return (

        <section className="container about">
        <h2 className="subtitle" id='portafolio'>Portafolio</h2>
        <h3 style={{ color: '#0086c1' }}>Nuestros productos y servicios:</h3>

        <div className="slider-container">
            <button className="prev-button" onClick={prevSlide}>❮</button>
            <div className="slides-container">
                {slides.map((slide, index) => (
                    <div key={slide.id} className={`slide ${index === currentSlide ? 'active' : ''}`}>
                        <img src={slide.image} alt={slide.title} />
                        {/* <h3>{slide.title}</h3> */}
                    </div>
                ))}
            </div>
            <button className="next-button" onClick={nextSlide}>❯</button>
        </div>

        </section>
    );
}

export default Slider;
