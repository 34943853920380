export default function Home() {
    return(
        <div>
            <section className="container about" id="quienes_somos">
                <h2 className="subtitle">¿Quiénes somos?</h2>
                <p className="about__paragraph">
                    Somos una empresa líder en ventas, con mas de 30 años, al servicio industrial con los mejores productos para satisfacer las necesidades del cliente, comercializamos maquinas y herramientas industriales , a través de un efectivo trabajo en equipo, que de manera responsable y oportuna garantice una excelente calidad en todos los equipos , y que a su vez mejore continuamente la calidad de vida de quienes se relacionan con nosotros.
                </p>

                <h2 className="subtitle">Nuestros objetivos:</h2>
                <ul className="about__paragraph">
                    <li>Ser el aliado confiable en el suministro de herramientas para el sector industrial y metalmecánico, donde se brindan productos, servicios y asesoría a la medida de las necesidades del cliente.</li>
                    <li>Con el respaldo de ELKAPA Servicios y Soluciones, ser la opción integral para nuestros clientes, brindando asesoría en la adquisición de maquinaria nacional e importada, herramientas, repuestos y mantenimiento.</li>
                </ul>
            </section>

        </div>
    );
}