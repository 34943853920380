import React from 'react';

export default function QuotationForm() {
  return (
    <div className="form-card">
        <section id='cotizar'>
            <h2>Solicitud de Cotización</h2>
            <form className="footer__form" action="https://formspree.io/f/mnqevrep" method="POST">
                <label htmlFor="fullName">Nombre completo:</label>
                    <input type="text" id="fullName" name="Nombre" placeholder="Ingresar nombre" required/>

                <label htmlFor="company">Empresa:</label>
                    <input type="text" id="company" name="Empresa" placeholder="Ingresar empresa"/>

                <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="Email" placeholder="Ingresar email" required/>

                <label htmlFor="phoneNumber">Celular:</label>
                    <input type="tel" id="phoneNumber" name="Celular" placeholder="Ingresar celular" required/>

                <label htmlFor="message">Descripción de solicitud:</label>
                    <textarea type="message" id="message" name="Solicitud" placeholder="Ingresar productos a cotizar" required/>

                <button type="submit">Enviar</button>
            </form>
        </section>
    </div>
  );
}