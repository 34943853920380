import './style.css';
import Footer from './components/footer.js'
import Home from './components/home.js';
import Slider from './components/slider.js'
import PayInto from './components/pay_into.js'
import QuotationForm from './components/add.js'
import Logo from './img/logo.png'


function App() {
  return (

    <div className="App">

      <MenuPrincipal titulo={'INDUSTRIAL FERRETERA MEDELLIN S.A.S'}></MenuPrincipal>

      <Home></Home>

      <PayInto></PayInto>

      <Slider></Slider>

      <QuotationForm></QuotationForm>

      <Footer></Footer>

    </div>
  );
}

function MenuPrincipal({titulo}) {
  
  return (
      <div className='hero'>
        <nav className="nav container">
        <div className="nav__logo">
            <h2 className="nav__title"><b>{titulo}</b></h2> 
        </div>

        <ul className="nav__link nav__link--menu">
            <li className="nav__items">
                <a href="#inicio" className="nav__links">Inicio</a>
            </li>

            <li className="nav__items">
                <a href="#quienes_somos" className="nav__links">Quiénes somos</a>
            </li>

            <li className="nav__items">
                <a href="#portafolio" className="nav__links">Portafolio</a>
            </li>

            <li className="nav__items">
                <a href="#footer" className="nav__links">Contacto</a>
            </li>

        </ul>

    </nav>

    <section className="hero__container container" id='inicio'>
        <h1 className="hero__title">Innovación industrial, solucionamos sus necesidades con calidad y experiencia.</h1>
      
        <figure className="knowledge__picture">
            <img src={Logo} alt="Computer" className="knowledge__img"/>
        </figure>
      
        <a href="#cotizar" className="cta" id="login">Cotizar con nosotros</a>
        
    </section>
    </div>
  );
}

export default App;
