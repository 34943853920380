import logo_blanco from "../img/logo_blanco.png";

export default function Footer() {
    return (
    <div>
    <footer className="footer">
        <section className="footer__container container" id="footer">

             <div className="footer__column">

                <h2 className="footer__title">Contáctenos</h2>
                             
                <p className="nav__links"> 
                    <box-icon name='envelope' color='#ffffff' ></box-icon>
                    &nbsp; <a href="mailto:induferrett@gmail.com" className="nav__links">induferrett@gmail.com</a>  
                </p>

               <p className="nav__links"> 
                    <box-icon name='map' color='#ffffff' ></box-icon>
                    &nbsp; <a href="https://maps.app.goo.gl/atv66c5jnFnKFUgT7" className="nav__links">Carrera 50 # 40-90</a>
                </p>

               <p className="nav__links">
                    <box-icon name='contact' type='solid' color='#ffffff' ></box-icon>
                    &nbsp; (604) 232 1362
                </p>
               <p className="nav__links">
                    <box-icon name='phone' color='#ffffff' ></box-icon>
                    &nbsp; (+57) 317 442 5477
                </p>

                <br/>

                <ul className="nav__link nav__link--menu">
                    <li className="nav__items">
                        <a href="#inicio" className="nav__links">Inicio</a>
                    </li>

                    <li className="nav__items">
                        <a href="#quienes_somos" className="nav__links">Quiénes somos</a>
                    </li>

                    <li className="nav__items">
                        <a href="#portafolio" className="nav__links">Portafolio</a>
                    </li>

                    <li className="nav__items">
                        <a href="#footer" className="nav__links">Contacto</a>
                    </li>
                </ul>

               {/* <form class="footer__form" action="https://formspree.io/f/mpzvrnap" method="POST">
                    <h2 class="footer__newsletter">Cotiza con nosotros:</h2>
                    <div class="footer__inputs">
                        <input type="email" placeholder="Email:" class="footer__input" name="_replyto"/>
                        <input type="submit" value="Enviar" class="footer__submit"/>
                    </div>
                 </form> */}
            

            </div>

            <div className="footer__column">
                <h2 className="footer__title">Horarios de Atención</h2>
                
                <h4 className="nav__links"> De Lunes a Viernes:</h4>
                <p className="nav__links"> 8:00 AM a 5:00 PM</p>
                <h4 className="nav__links"> Sábados:</h4>
                <p className="nav__links">8:30 AM a 1:00 PM</p>
            </div>

            <div className="footer__column">
                <h2 className="footer__title">Otros</h2>                
                <p><a href="https://drive.google.com/file/d/1DaTsU3Jx9vA32W4EGU1Hh2g94DE_wi0I/view?usp=drive_link" target="_blank" className="nav__links"  rel="noreferrer">Politica de tratamiento de datos</a></p>
                <p className="nav__links">Reconocimiento 30+ años</p>
                <img className="footer__img" src={logo_blanco} alt="Logo"/>
            </div>

        </section>

        <section className="footer__copy container">
            <div className="footer__social">
                <a href="www.facebook.com" target="_blank" className="footer_icons">
                 <box-icon name='facebook-circle' type='logo' color='#ffffff' size="md"></box-icon>
                </a>
                <a href="www.instagram.com" target="_blank" className="footer_icons">
                <box-icon name='instagram' type='logo' color='#ffffff' size="md"></box-icon>
                </a>
            </div>

            <h3 className="footer__copyright">&copy; Todos los derechos reservados. Optimizado por Dylan Montoya.</h3>
        </section>
    </footer>

    <p className="whasapp"><a href="https://wa.link/c7len1" className="nav__links"><box-icon name='whatsapp' type='logo' animation='tada' color='#35ae1e' size="78.5px"></box-icon></a></p>
    
    </div>

    );
}