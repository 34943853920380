export default function PayInto() {
    return (
    <div>
        <section class="price container">
            <h2 class="subtitle">¿Cómo nos identificamos?</h2>

            <div class="price__table">
                <div class="price__element">
                    <h3 class="price__price">Misión</h3>

                    <div class="price__items">
                        <p class="price__features">Somos una empresa dedicada a la comercialización de herramientas, accesorios, equipos y productos de alta calidad, para el sector industrial. Enfocados en satisfacer las necesidades del mercado, con personal altamente calificado, que brinda asesoría y acompañamiento a nuestros clientes, para el desarrollo satisfactorio de sus proyectos y el crecimiento de su negocio.</p>
                    </div>
                </div>


                <div class="price__element price__element--best">
                    <h3 class="price__price">Valores corporativos</h3>

                    <div class="price__items">
                        <p class="price__features">Trabajo en equipo</p>
                        <p class="price__features">Compromiso</p>
                        <p class="price__features">Disciplina</p>
                        <p class="price__features">Honestidad</p>
                        <p class="price__features">Responsabilidad</p>
                    </div>
                </div>


                <div class="price__element">
                    <h3 class="price__price">Visión</h3>

                    <div class="price__items">
                        <p class="price__features">Ser reconocidos como la ferretería líder en la región, destacados por la calidad de nuestros productos y el acompañamiento con personal idóneo, para el mejoramiento de sus procesos productivos, lo que permitirá excelente experiencia al cliente y aportando al crecimiento y bienestar de nuestros clientes y colaboradores.</p>

                    </div>
                </div>


            </div>
        </section>
    </div>

    );
}